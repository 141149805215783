import { MealModes, SelectedMeals, DietPackage } from 'common/types';
import { defaultWithoutWeekend } from '../../constants';
import type { Basket, DeliveryTimeConfig, DietConfiguration } from '../../types';
import { getDietLenghtOptions } from '../getDietLenghtOptions';
import { mapBasketMealsToSelectedMeals } from '../mapBasketMealsToSelectedMeals';
import { InitialFormState } from './initialFormState';

const undefinedId = -1;

export const baseCalculateInitialFormState = (
  data: DietConfiguration,
  deliveryTimeConfig: DeliveryTimeConfig
): InitialFormState => {
  const basket: Basket | undefined = data.baskets.values().next().value;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dietPackage: any = undefined;
  data.packages.forEach((value) => {
    if (!dietPackage || dietPackage.priority < value.priority) {
      dietPackage = value;
    }
  });

  const dietLengthOptions = getDietLenghtOptions(defaultWithoutWeekend);
  const dietLengthOption =
    dietLengthOptions.find((o) => {
      return o.type === 'custom';
    }) ?? dietLengthOptions[0];

  return {
    basket: basket?.id ?? undefinedId,
    mealMode: MealModes.comfort,
    meals: basket ? mapBasketMealsToSelectedMeals(basket) : (new Map() as SelectedMeals),
    dietPackage: dietPackage?.id ?? undefinedId,
    dietVariant: dietPackage?.defaultVariant ?? undefinedId,
    withoutWeekends: defaultWithoutWeekend,
    dietLength: dietLengthOption.id,
    customDeliveryDates: [deliveryTimeConfig.firstDeliveryAt],
    continuousFirstDeliveryDate: deliveryTimeConfig.firstDeliveryAt,
    paymentType: undefined,
    isSetFromUrl: false
  };
};
