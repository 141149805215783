import { z } from 'zod';

import { DietVariant, DietVariantResponseSchema } from './dietVariant';
import { HtmlText } from './htmlText';
import { Id } from './id';
import { DietPackageRole } from '../../features/orders/types/dietPackageRole';
import { ImageUrl } from './imageUrl';

export interface DietPackage {
  id: Id;
  name: string;
  description: HtmlText;
  variants: Id[];
  defaultVariant: Id;
  role: DietPackageRole;
  priority: number;
  variantModels: DietVariant[];
  defaultVariantModel: DietVariant;
  shortDescription: HtmlText;
  image: ImageUrl;
}

export const DietPackageResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  variants: z.array(DietVariantResponseSchema),
  clientDescription: z
    .string()
    .nullable()
    .transform((val) => val ?? ''),
  shortDescription: z
    .string()
    .nullable()
    .transform((val) => val ?? ''),
  order: z.number(),
  role: z.enum([DietPackageRole.PACKAGE, DietPackageRole.DIET, DietPackageRole.SPECIAL]),
  priority: z.number()
});
