import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, matchPath } from 'react-router-dom';

import { themeDefinition } from 'common/components/Theme';

import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

import { country } from 'config';
import { zopimScript } from './zopimScript';

const zopimDisabledRoutes = [
  RoutesDefinition.order,
  RoutesDefinition.createDiet,
  RoutesDefinition.dietVariantPreview,
  RoutesDefinition.dietPackagePreview,
  RoutesDefinition.orderSummary,
  RoutesDefinition.userDataOrder,
  RoutesDefinition.personalDataOrder,
  RoutesDefinition.deliveryAddressesOrder,
  RoutesDefinition.deliveryInfoOrder,
  RoutesDefinition.addMealsConfigurator,
  RoutesDefinition.addMealsSummary
];

export const useZendeskZopim = () => {
  const [zendeskBootstraped, setZendeskBootstraped] = useState(false);
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const bootstrapZopimCallback = useCallback(() => {
    setZendeskBootstraped(true);
    window.$zopim.livechat.setLanguage('pl');
    window.$zopim.livechat.button.setColor(themeDefinition.palette.primary.main);
    window.$zopim.livechat.window.setTitle(t('zopimChatWidget.windowTitle'));
    window.$zopim.livechat.concierge.setName(t('zopimChatWidget.conciergeName'));
    window.$zopim.livechat.concierge.setTitle(t('zopimChatWidget.conciergeTitle'));

    // TODO: add image with import
    window.$zopim.livechat.concierge.setAvatar('/logo-mobile.svg');
  }, [t]);

  useEffect(() => {
    if (country === 'poland') {
      zopimScript(bootstrapZopimCallback);
    }
  }, [bootstrapZopimCallback]);

  useEffect(() => {
    if (!zendeskBootstraped) {
      return;
    }

    const isUserOnDisabledRoute = zopimDisabledRoutes.some((singleRoute) => {
      return matchPath(pathname, {
        path: singleRoute,
        exact: true
      });
    });

    if (isUserOnDisabledRoute) {
      window.$zopim.livechat.button.hide();
    } else {
      window.$zopim.livechat.button.show();
    }
  }, [zendeskBootstraped, pathname]);
};
