import { Grid } from '@material-ui/core';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { MobileOrderSummary, PriceSummary } from 'features/orders';
import { Footer, OrderHeader } from 'layouts/components';
import { lazy, ReactNode } from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { BaseDashboardLayout } from './BaseDashboardLayout';

const SummaryComponent = lazy(() => import('features/orders/components/Summary/Summary'));

interface NewOrderLayoutProps {
  children: NonNullable<ReactNode>;
  isLoading?: boolean;
  backUrl: RouterLinkProps['to'];
  onBackClick?: VoidFunction;
  cta: ReactNode;
  mobileFooterType?: 'basic' | 'full';
  summaryFooter?: ReactNode;
}

export const NewOrderLayout = ({
  children,
  isLoading,
  backUrl,
  onBackClick,
  cta,
  mobileFooterType = 'basic',
  summaryFooter
}: NewOrderLayoutProps) => {
  const isMobile = useMobileScreenSizeDetector();

  const MobileFooter = mobileFooterType === 'basic' ? PriceSummary : MobileOrderSummary;
  return (
    <BaseDashboardLayout
      header={<OrderHeader backUrl={backUrl} onClick={onBackClick} />}
      fullHeight={isMobile}
      isLoading={isLoading}
      footer={isMobile ? <MobileFooter button={cta} /> : <Footer />}
    >
      <Grid container spacing={3}>
        <Grid container direction="column" item md={8}>
          {children}
        </Grid>
        {!isMobile && (
          <Grid container item md={4}>
            <SummaryComponent cta={cta} footer={summaryFooter} paymentsInformationSection />
          </Grid>
        )}
      </Grid>
    </BaseDashboardLayout>
  );
};
