import { useApi } from 'features/apiProvider';
import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { defaultWithoutWeekend } from 'features/orders';
import { useNotifyDialog } from 'common/contexts';
import { RetryButton } from 'common/components';
import { DietPackage, DietVariant } from 'common/types';
import {
  selectedMealsPositionTransformer,
  urlSearchParamsTransformer
} from 'features/orders/utils/calculateInitialFormState/calculateInitialFormStateForHomepageConfigurator';
import { useLocationQuery } from 'common/hooks';
import { fetchDietDayPrice as fetchDietDayPriceRequest } from 'features/orders/api/fetchDietDayPrice';
import dayjs from 'dayjs';
import { fetchAvailableProducts as fetchAvailableProductsRequest } from '../api';
import { SimpleBasket, SimpleBasketMeal } from '../types';

interface HomePageConfiguratorContextShape {
  selectedPackage: DietPackage | undefined;
  selectedVariant: DietVariant | undefined;
  calories: number;
  selectedMeals: SimpleBasketMeal[];
  dietLength: number;
  isLoading: boolean;
  pricePerDay: number;
  totalPrice: number;
  priceToPay: number;
}

interface HomePageConfiguratorProviderProps {
  children: React.ReactNode;
}

const initialValues: Omit<HomePageConfiguratorContextShape, 'isLoading'> = {
  selectedPackage: undefined,
  calories: 0,
  selectedVariant: undefined,
  dietLength: 0,
  selectedMeals: [],
  pricePerDay: 0,
  totalPrice: 0,
  priceToPay: 0
};

const HomePageConfiguratorContext = createContext<HomePageConfiguratorContextShape | undefined>(undefined);

export const HomePageConfiguratorProvider = ({ children }: HomePageConfiguratorProviderProps): JSX.Element => {
  const { getApiClient } = useApi();
  const { showErrorDialog, hide } = useNotifyDialog();
  const locationQuery = useLocationQuery();

  const handleApiError = () => {
    showErrorDialog({
      message: 'notificationDialog.error.message',
      cta: (
        <RetryButton
          onClick={() => {
            fetchAvailableProducts.refetch().then(() => fetchDietDayPrice.refetch());
            hide();
          }}
        />
      )
    });
  };

  const fetchAvailableProducts = useQuery({
    queryKey: 'fetchAvailableProducts',
    queryFn: () => fetchAvailableProductsRequest(getApiClient()),
    onError: handleApiError,
    select: (responseData) => {
      const a = urlSearchParamsTransformer(locationQuery);

      let selectedPackage: DietPackage | undefined = undefined;
      if (responseData.packages.has(a.packageId)) {
        selectedPackage = responseData.packages.get(a.packageId);
      } else {
        responseData.packages.forEach((value) => {
          if (!selectedPackage || selectedPackage.priority < value.priority) {
            selectedPackage = value;
          }
        });
      }

      const requestedVariant = responseData.variants.get(a.variantId);
      let selectedVariant: DietVariant | undefined;
      if (selectedPackage) {
        if (requestedVariant && selectedPackage.variants.includes(requestedVariant.id)) {
          selectedVariant = requestedVariant;
        } else {
          selectedVariant = responseData.variants.get(selectedPackage.defaultVariant);
        }
      }
      let selectedBasket: SimpleBasket | undefined;
      responseData.baskets.forEach((b) => {
        if (b.calorific === a.calorific) {
          selectedBasket = b;
        }
      });
      if (!selectedBasket) {
        selectedBasket = responseData.baskets.values().next().value;
      }
      let selectedMeals: SimpleBasketMeal[] = [];
      if (selectedBasket) {
        const selectedMealsPositions = selectedMealsPositionTransformer(locationQuery);
        selectedMeals = selectedBasket.basketMeals.filter((_, index) => {
          return selectedMealsPositions.includes(index + 1);
        });
      }
      const calories = selectedMeals.reduce((sum, selectedMeal) => {
        return sum + selectedMeal.size.calories;
      }, 0);
      return {
        selectedPackage,
        selectedVariant,
        calories,
        selectedMeals,
        dietLength: a.daysCount
      };
    }
  });

  const fetchDietDayPrice = useQuery({
    queryFn: () => {
      return fetchDietDayPriceRequest({
        apiClient: getApiClient(),
        requestData: {
          dietLength: fetchAvailableProducts.data?.dietLength ?? 0,
          discountCode: null,
          weekendsIncluded: defaultWithoutWeekend,
          package: fetchAvailableProducts.data?.selectedPackage?.id ?? -1,
          sizeIds: fetchAvailableProducts.data?.selectedMeals.map((m) => m.size.id) ?? [],
          days: [],
          selectedProfile: null,
          startDate: dayjs()
        }
      });
    },
    onError: handleApiError,
    queryKey: [
      'HomepageFetchDietDayPrice',
      fetchAvailableProducts.data?.dietLength,
      fetchAvailableProducts.data?.selectedPackage?.id
    ],
    enabled: fetchAvailableProducts.data !== undefined
  });

  const value = {
    ...initialValues,
    ...fetchAvailableProducts.data,
    ...fetchDietDayPrice.data,
    isLoading: fetchAvailableProducts.isLoading || fetchDietDayPrice.isLoading
  };

  return <HomePageConfiguratorContext.Provider value={value}>{children}</HomePageConfiguratorContext.Provider>;
};

export const useHomePageConfigurator = () => {
  const context = useContext(HomePageConfiguratorContext);
  if (context === undefined) {
    throw new Error('useHomePageConfigurator must be used within a HomePageConfiguratorContext');
  }
  return context;
};
