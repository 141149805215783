import { DiscountCodeSchema, MealModes, selectedMealSchema } from 'common/types';
import dayjs, { Dayjs } from 'dayjs';

import { z } from 'zod';
import { PaymentType } from './paymentType';

export const createDietFormMaxFieldsMaxLength = {
  dietName: 32
};

const dayjsSchema = z.custom<Dayjs>((data) => data instanceof dayjs);

export const createDietFormSchema = z.object({
  basket: z.number(),
  mealMode: z.nativeEnum(MealModes),
  dietPackage: z.number(),
  dietLength: z.number(),
  withoutWeekends: z.boolean(),
  dietVariant: z.number(),
  dietName: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.dietNameRequired')
        .max(createDietFormMaxFieldsMaxLength.dietName, 'errors.dietNameToLong')
        .safeParse(val);

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    }),
  // { mealTypeId: { id: nasze wewnętrzne id, mealId: id posiłku }}
  meals: z.map(z.number(), z.array(selectedMealSchema)),
  customDeliveryDates: z.array(dayjsSchema).default([]),
  continuousFirstDeliveryDate: z.custom<Dayjs>((data) => {
    // TODO: In the react-hook-form reset fn clone object and somehow lost prototype. Need to investigate
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tmp = data as any;
    return dayjs(tmp?.$d).isValid();
  }),
  discountCode: DiscountCodeSchema.nullable(),
  paymentType: z.enum([PaymentType.ONLINE, PaymentType.PAYPAL, PaymentType.MONEYBOX]),
  isSetFromUrl: z.boolean()
});

export const extendDietFormSchema = createDietFormSchema.extend({
  dietName: z
    .string()
    .nullable()
    .transform((val) => val ?? '')
});

export type CreateDietFormData = z.infer<typeof createDietFormSchema>;
