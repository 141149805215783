import { ApiClient } from 'features/apiProvider';
import { z } from 'zod';

const MealsPriceResponseSchema = z.object({
  packages: z
    .array(
      z.object({
        sizeId: z.number(),
        price: z.string().transform((p) => Number(p)),
        packageId: z.number()
      })
    )
    .optional()
    .transform((val) => val ?? [])
});

export const fetchMealsPrices = async (apiClient: ApiClient['apiClient'], date: string) => {
  const response = await apiClient.get(`frontend/secure/diet/prices/${date}`).json();
  const parsedResponse = MealsPriceResponseSchema.parse(response);
  return parsedResponse.packages.map((meal) => ({
    id: meal.sizeId,
    price: meal.price,
    packageId: meal.packageId
  }));
};

export type MealPrice = Awaited<ReturnType<typeof fetchMealsPrices>>[number];
