import { useApi } from 'features/apiProvider';
import { useState, createContext, useContext, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { clearCart as clearCartRequest, fetchDaysWithDeliveries } from 'features/orders';
import { useNotifyDialog } from 'common/contexts';
import { RetryButton } from 'common/components';
import { OrderHeader } from 'layouts/components';
import { BaseDashboardLayout } from 'layouts';
import { useProfiles } from 'features/profiles';
import { Address } from 'features/addresses/types';
import { useUser } from 'features/user';
import { CalendarDate, Id } from 'common/types';
import { Dayjs } from 'dayjs';
import { localeCode } from 'config/constants/localeCode';
import { useMealPrices } from 'services/mealPrices';
import { MealPrice } from 'services/mealPrices/fetchMealPrices';

import { fetchSizesData, SizeData } from 'features/orders/api/fetchSizesData';
import { fetchDeliveryTime } from 'features/orders/api/fetchDeliveryTime';
import { fetchAvailableProducts } from 'features/orders/api/fetchAvailableProducts';
import { DeliveryTimeConfig, DietConfiguration } from '../../types';
import { CreateDietFormProvider } from './CreateDietFormProvider';
import { DietPackageRole } from '../../types/dietPackageRole';

interface DietConfigurationContextShape {
  config: DietConfiguration;
  standardDeliveryAddress: Address | undefined;
  setStandardDeliveryAddress: (address: Address) => void;
  festiveDeliveryAddress: Address | undefined;
  setFestiveDeliveryAddress: (address: Address) => void;
  configuredDiet: boolean;
  setConfiguredDiet: (value: boolean) => void;
  isNewOrder: boolean;
  daysWithDeliveries: CalendarDate[];
  mealPrices: MealPrice[];
  sizesData: SizeData[];
  deliveryTimeConfig: DeliveryTimeConfig;
  setDietPackageRole: (dietPackageRole: DietPackageRole | undefined) => void;
  dietPackageRole: DietPackageRole | undefined;
}

interface DietConfigurationContextProviderProps {
  children: React.ReactNode;
}

const DietConfigurationContext = createContext<DietConfigurationContextShape | undefined>(undefined);

export const DietConfigurationProvider = ({ children }: DietConfigurationContextProviderProps): JSX.Element => {
  const { getApiClient } = useApi();
  const apiClient = getApiClient();
  const { selectedProfileId } = useProfiles();
  const { addresses } = useUser();
  const { data: mealPrices } = useMealPrices();
  const [standardDeliveryAddress, setStandardDeliveryAddress] = useState<Address | undefined>(() =>
    addresses.data.find((address) => address.isStandardByDefault)
  );
  const [festiveDeliveryAddress, setFestiveDeliveryAddress] = useState<Address | undefined>(() =>
    addresses.data.find((address) => address.isSaturdayByDefault)
  );
  const { showErrorDialog } = useNotifyDialog();
  const [isCartClear, setIsCartClear] = useState(false);

  const [config, setConfig] = useState<DietConfiguration>();
  const [deliveryTimeConfig, setDeliveryTimeConfig] = useState<DeliveryTimeConfig>();

  const [dietPackageRole, setDietPackageRole] = useState<DietPackageRole | undefined>(undefined);

  const [configuredDiet, setConfiguredDiet] = useState(false);

  const isFirstOrder = selectedProfileId === null;
  const calculateFirtDeliveryAt = (response: Dayjs) => {
    const isWeekend = response.isWeekend();
    if (localeCode === 'de') {
      if (isWeekend) {
        if (response.day() === 6) {
          return response.add(2, 'day');
        }
        return response.add(1, 'day');
      }
      return response;
    }
    return response;
  };
  const handleApiError = () => {
    showErrorDialog({
      message: 'notificationDialog.error.message',
      cta: <RetryButton onClick={handleRetryButtonClick} />
    });
  };

  const clearCart = useMutation({
    mutationFn: clearCartRequest,
    onSuccess: () => setIsCartClear(true),
    onError: handleApiError
  });

  const { data: sizesData, refetch: sizeDataRefetch } = useQuery({
    queryKey: 'sizes-data',
    queryFn: () => fetchSizesData(apiClient),
    onError: handleApiError
  });

  const { refetch: deliveryTimeRefetch } = useQuery({
    queryKey: 'delivery-time',
    queryFn: () => fetchDeliveryTime(apiClient),
    onError: handleApiError,
    onSuccess: (data) => {
      setDeliveryTimeConfig({
        disabledDays: data.disabledDays,
        firstDeliveryAt: calculateFirtDeliveryAt(data.firstDeliveryAt)
      });
    }
  });

  const dietConfiguration = useQuery({
    queryKey: 'fetch-available-products',
    queryFn: () => fetchAvailableProducts(apiClient),
    onSuccess: (response) => {
      setConfig(response);
    },
    onError: handleApiError
  });

  const daysWithDeliveries = useQuery({
    queryKey: ['daysWithDeliveriesRequest', selectedProfileId],
    queryFn: () => fetchDaysWithDeliveries({ apiClient, profileId: selectedProfileId as Id }),
    enabled: selectedProfileId !== null
  });

  const handleRetryButtonClick = () => {
    clearCart.mutate({
      apiClient
    });
    sizeDataRefetch();
    deliveryTimeRefetch();
    dietConfiguration.refetch();
  };

  useEffect(() => {
    clearCart.mutate({
      apiClient
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isCartClear || config === undefined || deliveryTimeConfig === undefined) {
    return <BaseDashboardLayout header={<OrderHeader backUrl="/" />} isLoading />;
  }

  const value = {
    config,
    standardDeliveryAddress,
    setStandardDeliveryAddress,
    festiveDeliveryAddress,
    setFestiveDeliveryAddress,
    configuredDiet,
    setConfiguredDiet,
    isNewOrder: isFirstOrder,
    mealPrices: mealPrices || [],
    sizesData: sizesData || [],
    daysWithDeliveries: daysWithDeliveries.data ?? [],
    deliveryTimeConfig,
    dietPackageRole,
    setDietPackageRole
  };
  return (
    <DietConfigurationContext.Provider value={value}>
      <CreateDietFormProvider>{children}</CreateDietFormProvider>
    </DietConfigurationContext.Provider>
  );
};

export const useDietConfiguration = () => {
  const context = useContext(DietConfigurationContext);
  if (context === undefined) {
    throw new Error('useDietConfiguration must be used within a DietConfigurationContext');
  }
  return context;
};
