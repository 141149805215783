import { z } from 'zod';

import { HtmlText, Id, ImageUrl } from 'common/types';

export interface DietVariant {
  id: Id;
  name: string;
  summary: string;
  description: HtmlText;
  image: ImageUrl;
  position: number;
}

export const DietVariantResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  title: z.string(),
  clientDescription: z
    .string()
    .nullable()
    .transform((val) => val ?? ''),
  active: z.boolean(),
  position: z.number(),
  image: z.string().nullable()
});
