import { country } from 'config';

const acceptInvitation = country === 'poland' ? '/polecam/:code' : '/recommend/:code';

export const RoutesDefinition = {
  dashboard: '/',
  dashboardFeatureDate: '/date/:date',
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  resetPasswordUpdate: '/user/remind-change-password/:token',
  user: '/user',
  inviteNewFriends: '/invite-new-friends',
  acceptInvitation,
  userDetails: '/user/my-data',
  userAdresses: '/user/addresses',
  editAdresses: '/user/addresses/:id',
  addNewDeliveryPoint: '/user/addresses/delivery-point',
  addNewAdresses: '/user/addresses/add',
  userApprovals: '/user/approvals',
  orderHistory: '/user/order-history',
  userInvoices: '/user/invoices',
  changePassword: '/user/change-password',

  moneybox: '/moneybox',
  moneyboxSummary: '/moneybox/summary',
  moneyboxWithdraw: '/moneybox/withdraw',
  moneyboxDeposit: '/moneybox/deposit',
  paymentMoneyboxThanks: '/moneybox/thanks/:value',

  order: '/order',
  createDiet: '/order/create',
  dietVariantPreview: '/order/variant',
  dietPackagePreview: '/order/package',

  orderSummary: '/order/summary',

  userDataOrder: '/order/user-data',
  personalDataOrder: '/order/user-data/personal-data',
  deliveryTypeSelect: '/order/user-data/delivery-type-select',
  deliveryPointOrder: '/order/user-data/delivery-point',
  deliveryAddressesOrder: '/order/user-data/delivery-addresses',
  deliveryInfoOrder: '/order/user-data/delivery-info',

  paymentOrderThanks: '/order/thanks/:orderId',

  addMealsConfigurator: '/diet/:dietId/add-meals/:date',
  addMealsSummary: '/diet/:dietId/add-meals/:date/summary',

  paymentAddMealsThanks: '/order/thanks/:orderId/:date',
  setup: '/setup',

  adminLogin: '/user/authorize/:refreshToken/:impersonatorToken',
  createProfile: '/user/create-profile',
  editProfile: '/user/edit-profile/:id'
};
