import { DietPackage, DietVariant, MealModes, SelectedMeal } from 'common/types';
import {
  AvailableBaskets,
  AvailableDietPackages,
  Basket,
  calculatePredefinedDateRange,
  defaultWithoutWeekend,
  DietConfiguration,
  getDietLenghtOptions,
  maxDaysIntervalToStartOrder
} from 'features/orders';
import { AvailableVariants, DeliveryTimeConfig } from 'features/orders/types';
import { nanoid } from 'nanoid';
import { InitialFormState } from './initialFormState';

export const selectedMealsPositionTransformer = (urlSearchParams: URLSearchParams) => {
  const MAX_NUMBER_OF_MEAL_TYPE = 6;
  return Array.from({ length: MAX_NUMBER_OF_MEAL_TYPE }, (v, i) => i)
    .map((i) => {
      return Number(urlSearchParams.get(`meals[${i}]`));
    })
    .filter((mealId) => mealId !== 0);
};

export const urlSearchParamsTransformer = (urlSearchParams: URLSearchParams) => {
  return {
    calorific: Number(urlSearchParams.get('calorie')),
    packageId: Number(urlSearchParams.get('type')),
    variantId: Number(urlSearchParams.get('diet')),
    daysCount: Number(urlSearchParams.get('days')),
    selectedMealsPositions: selectedMealsPositionTransformer(urlSearchParams)
  };
};

const basketAndSelectedMealsParamsTransformer = ({
  baskets,
  calorific,
  selectedMealsPositions,
  formData
}: {
  baskets: AvailableBaskets;
  calorific: number;
  selectedMealsPositions: number[];
  formData: InitialFormState;
}) => {
  let basket: Basket | undefined;
  baskets.forEach((b) => {
    if (b.calorific === calorific) {
      basket = b;
    }
  });
  let calculatedBasket = formData.basket;
  let calculatedMeals = formData.meals;
  if (basket) {
    calculatedBasket = basket.id;
    const entities = basket.basketMeals.reduce(
      (acc, basketMealType, index) => {
        const { mealType } = basketMealType;
        const selectedMeal: SelectedMeal[] = [];
        if (selectedMealsPositions.includes(index + 1)) {
          selectedMeal.push({ id: nanoid(), mealId: mealType.meals[0] });
        }
        acc.push([mealType.id, selectedMeal]);
        return acc;
      },
      [] as [number, SelectedMeal[]][]
    );
    calculatedMeals = new Map(entities);
  }
  return {
    basket: calculatedBasket,
    meals: calculatedMeals
  };
};

const packageAndVariantParamsTransfomer = ({
  packages,
  packageId,
  variants,
  variantId,
  formData
}: {
  packages: AvailableDietPackages;
  packageId: number;
  variants: AvailableVariants;
  variantId: number;
  formData: InitialFormState;
}) => {
  let dietPackage: DietPackage | undefined;

  packages.forEach((p) => {
    if (p.id === packageId) {
      dietPackage = p;
    }
  });

  let dietVariant: DietVariant | undefined;

  variants.forEach((v) => {
    if (v.id === variantId) {
      dietVariant = v;
    }
  });

  let calculatedDietPackage = formData.dietPackage;
  let calculatedDietVariant = formData.dietVariant;
  if (dietPackage) {
    calculatedDietPackage = dietPackage.id;
    if (dietVariant && dietPackage.variants.includes(dietVariant.id)) {
      calculatedDietVariant = dietVariant.id;
    } else {
      calculatedDietVariant = dietPackage.defaultVariant;
    }
  }

  return {
    dietPackage: calculatedDietPackage,
    dietVariant: calculatedDietVariant
  };
};

const daysCountParamTransfomer = ({
  formData,
  daysCount,
  deliveryTimeConfig
}: {
  formData: InitialFormState;
  daysCount: number;
  deliveryTimeConfig: DeliveryTimeConfig;
}) => {
  let { dietLength } = formData;
  let { customDeliveryDates } = formData;
  if (daysCount > 0) {
    const dietLengthOptions = getDietLenghtOptions(defaultWithoutWeekend);
    const dietLengthOption = dietLengthOptions.find((o) => {
      return o.type === 'custom';
    });
    if (dietLengthOption) {
      dietLength = dietLengthOption.id;
      customDeliveryDates = calculatePredefinedDateRange({
        disabledDays: deliveryTimeConfig.disabledDays,
        firstDate: deliveryTimeConfig.firstDeliveryAt,
        length: daysCount,
        maxDate: deliveryTimeConfig.firstDeliveryAt.add(maxDaysIntervalToStartOrder, 'd'),
        withoutWeekends: defaultWithoutWeekend
      });
    }
  }
  return {
    dietLength,
    customDeliveryDates
  };
};

export const calculateInitialFormStateForHomepageConfigurator = ({
  data,
  formData,
  deliveryTimeConfig,
  urlSearchParams
}: {
  formData: InitialFormState;
  data: DietConfiguration;
  deliveryTimeConfig: DeliveryTimeConfig;
  urlSearchParams: URLSearchParams;
}): InitialFormState => {
  const { calorific, daysCount, packageId, selectedMealsPositions, variantId } =
    urlSearchParamsTransformer(urlSearchParams);

  const { basket, meals } = basketAndSelectedMealsParamsTransformer({
    baskets: data.baskets,
    calorific,
    formData,
    selectedMealsPositions
  });

  const { dietPackage, dietVariant } = packageAndVariantParamsTransfomer({
    packages: data.packages,
    packageId,
    variants: data.variants,
    variantId,
    formData
  });

  const { customDeliveryDates, dietLength } = daysCountParamTransfomer({
    daysCount,
    formData,
    deliveryTimeConfig
  });

  return {
    ...formData,
    basket,
    mealMode: MealModes.comfort,
    dietPackage,
    dietVariant,
    meals,
    dietLength,
    customDeliveryDates,
    isSetFromUrl: !!packageId
  };
};
