import { z } from 'zod';
import { sortBy } from 'lodash';

import { Id, DietPackage, DietVariantResponseSchema, DietPackageResponseSchema, DietVariant } from 'common/types';

import { ApiClient } from 'features/apiProvider';

import { AvailableBaskets, Basket, BasketMeal, AvailableVariants, AvailableDietPackages } from '../types';
import { DietConfiguration } from '../types/dietConfigurator';

const photosUrl = process.env.REACT_APP_IMAGE_DIR;

type DietVariantResponse = z.TypeOf<typeof DietVariantResponseSchema>;

type DietPackageResponse = z.TypeOf<typeof DietPackageResponseSchema>;

const BasketMealResponseSchema = z.object({
  id: z.number(),
  mealType: z.object({
    id: z.number(),
    name: z.string(),
    position: z.number()
  }),
  size: z.object({
    id: z.number(),
    calorific: z.number(),
    nameForClient: z.string(),
    name: z.string()
  })
});

const BasketsResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  position: z.number(),
  active: z.boolean(),
  basketMeals: z.array(BasketMealResponseSchema)
});

type BasketsResponse = z.TypeOf<typeof BasketsResponseSchema>;

const fetchAvailableProductsResponseSchema = z.object({
  baskets: z.array(BasketsResponseSchema),
  packages: z.array(DietPackageResponseSchema),
  variants: z.array(DietVariantResponseSchema)
});

const dietVariantsTransformer = (data: DietVariantResponse[]): AvailableVariants => {
  const activeVariants = data.filter((variant) => variant.active);
  const sortedActiveVariantsByPosition = sortBy(activeVariants, (variant) => variant.position);
  const entites: [number, DietVariant][] = sortedActiveVariantsByPosition.map((variant) => {
    const variantId = Number(variant.id);
    return [
      variantId,
      {
        id: variantId,
        name: variant.name,
        summary: variant.title,
        description: variant.clientDescription,
        image: `${photosUrl}${variant.image}`,
        position: variant.position
      }
    ];
  });
  return new Map(entites);
};

const dietPackagesTransformer = (
  data: DietPackageResponse[],
  dietVariants: AvailableVariants
): AvailableDietPackages => {
  const sortedPackagesByOrder = sortBy(data, (p) => p.order);
  const parsedPackages = sortedPackagesByOrder.map((p) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let defaultVariantModel: any = undefined;
    const variantModels = p.variants.reduce((acc, v) => {
      if (dietVariants.has(v.id)) {
        acc.push(v);

        if (v.active && (!defaultVariantModel || defaultVariantModel.position > v.position)) {
          defaultVariantModel = v;
        }
      }

      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, [] as any[]);

    return {
      id: p.id,
      name: p.name,
      description: p.clientDescription,
      shortDescription: p.shortDescription,
      role: p.role,
      priority: p.priority,
      variants: variantModels.map((variantModel) => variantModel.id),
      defaultVariant: defaultVariantModel.id,
      image: defaultVariantModel ? `${photosUrl}${defaultVariantModel.image}` : '',
      variantModels,
      defaultVariantModel
    };
  });

  const entites: [Id, DietPackage][] = parsedPackages.map((p) => [p.id, p]);
  return new Map(entites);
};

const basketMealTypesTransfomer = (data: BasketsResponse['basketMeals']): BasketMeal[] => {
  const sortedMealTypesByPosition = sortBy(data, (m) => m.mealType.position);
  return sortedMealTypesByPosition.map((m) => ({
    id: m.id,
    mealType: {
      id: m.mealType.id,
      name: m.mealType.name,
      meals: [m.size.id],
      size: { sizeName: m.size.nameForClient, calorific: m.size.calorific, id: m.size.id }
    }
  }));
};

const basketsTransformer = (data: BasketsResponse[]): AvailableBaskets => {
  const activeBaskets = data.filter((b) => b.active);
  const sortedActiveBasketsByPosition = sortBy(activeBaskets, (b) => b.position);
  const parsedBasked = sortedActiveBasketsByPosition.reduce((acc: Map<Id, Basket>, currentItem) => {
    acc.set(currentItem.id, {
      id: currentItem.id,
      calorific: Number(currentItem.name),
      basketMeals: basketMealTypesTransfomer(currentItem.basketMeals)
    });
    return acc;
  }, new Map());

  return parsedBasked;
};

export const fetchAvailableProducts = async (apiClient: ApiClient['apiClient']): Promise<DietConfiguration> => {
  const response = await apiClient.get('frontend/configurator').json();

  const parsedResponse = fetchAvailableProductsResponseSchema.parse(response);

  const variants = dietVariantsTransformer(parsedResponse.variants);
  return {
    baskets: basketsTransformer(parsedResponse.baskets),
    packages: dietPackagesTransformer(parsedResponse.packages, variants),
    variants
  };
};
